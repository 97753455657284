@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
:root {
  --brand-primary-color: #2554e4;
  --brand-font-family: 'Poppins', sans-serif;
}

* {
  font-family: 'Poppins', sans-serif !important;
}
p {
  padding-bottom: 16px;
}
.app-max-width {
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
}

.welcome-title {
  color: #2554e4;
  font-size: 32px;
  font-weight: normal;
}

.sub-title-bold {
  font-size: 16px;
  font-weight: bold;
}

.text-description {
  font-size: 16px;
  max-width: 700px;
  margin: 16px auto;
}

.section-title {
  color: #0e2771 !important;
  font-size: 48px !important;
  font-weight: bold !important;
  margin: 32px auto 64px !important;
}

.contact-description {
  margin-top: 16px;
}

.form-style {
  padding: 32px;
}

.footer-style {
  color: #fcfdfd;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.footer-row {
  flex-direction: column;
}

.banner-ad {
  cursor: pointer;
  margin-bottom: 32px;
}

.contact-form {
  padding: 32px;
  margin-left: 32px;
}

.custom-main {
  overflow-x: visible !important;
}

.gradient-background {
  padding: 0 !important;
  margin: 80px 0 0 !important;
  background: rgb(252, 253, 253);
  background: linear-gradient(45deg, rgba(252, 253, 253, 0.9976584383753502) 0%, rgba(244, 227, 242, 0.30017944677871145) 50%, rgba(164, 255, 211, 0.1461178221288515) 100%);
}

.v-application .primary {
  background-color: #2554e4 !important;
  border-color: #2554e4 !important;
  font-family: 'Poppins', sans-serif !important;
}

.primary-cta {
  background-color: #1bf2bc !important;
  color: #0e2771 !important;
  box-shadow: none !important;
  border-radius: 50px !important;
  padding: 24px 32px !important;
  font-family: 'Poppins', sans-serif !important;
  text-transform: capitalize !important;
  font-weight: bold;
  font-size: 18px !important;
  height: 56px !important;
}

.v-application .secondary {
  background: rgb(244, 227, 242);
  background: radial-gradient(circle, rgba(244, 227, 242, 0.4990589985994398) 0%, rgba(252, 253, 253, 1) 50%, rgba(164, 255, 211, 0.5046612394957983) 100%);
}

.contact-message {
  padding: 0 32px;
}

.glassmorphism {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.section-title {
  text-align: center;
  color: #2554e4;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 40px;
}

.pain-points {
  justify-content: center !important;
}

.pain-point-card {
  max-width: 320px;
  text-align: center;
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.pain-point-icon {
  font-size: 48px;
  color: #2554e4;
  margin-bottom: 16px;
}

.pain-point-title {
  font-size: 20px;
  font-weight: bold;
  color: #2554e4;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif !important;
}

.pain-point-description {
  font-size: 16px;
  color: #333333;
}

.recaptcha-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}

.recaptcha-container .g-recaptcha {
  margin-right: 16px;
}

@media only screen and (max-width: 959px) {

  .contact-form,
  .form-style {
    margin-left: 0;
    ;
  }
}

@media only screen and (min-width: 768px) {
  .pain-points {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
  }
}

@media only screen and (max-width: 539px) {
  .contact-form {
    padding: 0;
  }

  .contact-message {
    padding: 0 16px;
  }

  .gradient-background {
    padding: 16px !important;
  }
}